import { BurguerMenu } from 'components/BurguerMenu'
import { Favorites } from 'components/Favorites'
import { HeaderBrand } from 'components/HeaderBrand'
import Link from 'next/link'
import { SearchForm } from 'components/Search'
import { ComponentType } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { RoleModel } from 'utils/role'

interface Props {
  role?: RoleModel
  category?: any
  zone?: any
  favorites?: any
}

export const SearchHeaderDesktop: ComponentType<Props> = ({ role, category, zone, favorites }) => (
  <Container>
    <Row>
      <Col>
        <div className="d-flex h-70px">
          <HeaderBrand className="mr-25" />
          <div className="d-flex align-items-center mr-auto">
            <Link href="/acerca/promueve-tu-propiedad">
              <a title="Promover mi propiedad" className="nav-anchor button-promote button-promote_header mr-20">
                Promover mi propiedad
              </a>
            </Link>
            <SearchForm role={role} category={category} zone={zone} />
          </div>
          <div className="d-flex align-items-center">
            <Favorites favorites={favorites} />
            <BurguerMenu />
          </div>
        </div>
      </Col>
    </Row>
  </Container>
)
