import { RENT_MODEL, SALE_MODEL, SALE_RENT_MODEL } from 'consts'

export const findRole = (input: any) => {
  switch (input) {
    case SALE_MODEL.uuid:
    case SALE_MODEL.slug:
      return SALE_MODEL

    case RENT_MODEL.uuid:
    case RENT_MODEL.slug:
      return RENT_MODEL

    case SALE_RENT_MODEL.uuid:
    case SALE_RENT_MODEL.slug:
      return SALE_RENT_MODEL

    default:
      return undefined
  }
}

export type RoleModel = Exclude<ReturnType<typeof findRole>, undefined>
