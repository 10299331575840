import { CoverBackground } from 'components/CoverBackground'
import Link from 'next/link'
import numeral from 'numeral'
import { ComponentType } from 'react'
import { pluralize } from 'utils/plural'
import { findRole } from 'utils/role'

interface Props {
  property: any
}

export const PropertyMapCard: ComponentType<Props> = ({ property }) => {
  const role = findRole(property.role)

  return (
    <div className="rounded-md shadow bg-white font-family-cabin" style={{ width: 200, zIndex: 100 }}>
      <CoverBackground
        url={property.cover?.thumbnailUrl}
        className="h-160px position-relative rounded-md-top bg-porcelain"
      >
        <div className="position-absolute d-flex" style={{ bottom: -10, right: 4 }}>
          {property.role.includes('sale') && (
            <div className="mx-4px px-6 py-3px rounded bg-primary text-white font-weight-bold">V</div>
          )}
          {property.role.includes('rent') && (
            <div className="mx-4px px-6 py-3px rounded bg-success text-white font-weight-bold">R</div>
          )}
        </div>
      </CoverBackground>
      <div className="px-10 py-12">
        <h6 className="mb-5px font-size-14">{property.zone.name}</h6>
        {(!!property.places.rooms || !!property.places.bathrooms) && (
          <h6 className="mb-12 font-size-14 font-weight-400 text-trout">
            {!!property.places.rooms && pluralize(property.places.rooms, 'recámaras', 'recámara')}
            {!!property.places.rooms && property.places.bathrooms && ', '}
            {!!property.places.bathrooms && pluralize(property.places.bathrooms, 'baños', 'baño')}
          </h6>
        )}
        <div className="d-flex justify-content-between">
          <h6 className="mb-0 font-size-14 font-weight-bold">
            {!!property.sale?.price ? (
              <>
                {numeral(property.sale.price).format('$0,0')} {property.currency}
              </>
            ) : !!property.rent?.monthPrice ? (
              <>
                {numeral(property.rent.monthPrice).format('$0,0')} {property.currency}/mes
              </>
            ) : null}
          </h6>
          <Link
            href="/[categorySlug]/[roleSlug]/[zoneSlug]/[propertyUuid]"
            as={`/${property.category.slug}/${role?.slug}/${property.zone.slug}/${property.uuid}`}
          >
            <a
              title={property.name}
              className="card-link stretched-link font-size-12 font-weight-bold text-trout"
              target="_blank"
            >
              {property.uuid}
            </a>
          </Link>
        </div>
      </div>
    </div>
  )
}
