import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import { useStoreActions } from 'hooks/useStore'
import Link from 'next/link'
import { forwardRef, useEffect, useState } from 'react'
import { GoChevronDown, GoChevronUp, GoHeart } from 'react-icons/go'
import { Button, Col, Collapse, Container, Row } from 'reactstrap'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f8f9fa;
  z-index: 1030;
  overflow: auto;
`

interface Props {
  favorites?: any
}

export const FlyoutMenuMobile = forwardRef<HTMLDivElement, Props>(({ favorites }, ref) => {
  const [companyOpen, setCompanyOpen] = useState(false)
  const setInvader = useStoreActions((actions) => actions.ui.setInvader)

  useEffect(() => {
    if (typeof ref === 'object' && ref?.current) {
      disableBodyScroll(ref.current)
    }

    return clearAllBodyScrollLocks
  }, [])

  return (
    <Wrapper ref={ref}>
      <Container>
        <Row className="py-10">
          <Col>
            {favorites?.total > 0 && (
              <div className="py-10">
                <h6
                  className="mb-0 py-10 text-uppercase text-carise-red font-size-15 font-weight-bold"
                  onClick={() => setInvader('favorites')}
                >
                  Favoritos <GoHeart className="ml-5px" /> {favorites.total}
                </h6>
              </div>
            )}
            <div className="py-10">
              <h6 className="mb-0 py-10 text-uppercase text-primary font-size-15 font-weight-bold">Servicios</h6>
              <Link href="/explorar/operacion/[roleSlug]" as="/explorar/operacion/en-venta">
                <a title="Comprar" className="py-7 d-block text-raven">
                  Comprar
                </a>
              </Link>
              <Link href="/explorar/operacion/[roleSlug]" as="/explorar/operacion/en-renta">
                <a title="Rentar" className="py-7 d-block text-raven">
                  Rentar
                </a>
              </Link>
              <a href="https://wa.me/5219993351899" title="Promover mi propiedad" className="py-7 d-block text-malachite">
                Promover mi propiedad
              </a>
            </div>
            <div className="py-10">
              <Link href="/blog">
                <a
                  title="Nuestro Blog"
                  className="py-10 d-block text-uppercase text-primary font-size-15 font-weight-bold"
                >
                  Blog
                </a>
              </Link>
            </div>
            <div className="py-10">
              <h6
                className="mb-0 py-10 d-flex align-items-center justify-content-between text-uppercase text-primary font-size-15 font-weight-bold"
                onClick={() => setCompanyOpen(!companyOpen)}
              >
                Compañía
                {companyOpen ? (
                  <GoChevronUp size={16} className="text-primary" />
                ) : (
                  <GoChevronDown size={16} className="text-gray-chateau" />
                )}
              </h6>
              <Collapse isOpen={companyOpen}>
                <Link href="/acerca">
                  <a title="Sobre Nosotros" className="py-7 d-block text-raven">
                    Sobre Nosotros
                  </a>
                </Link>
                <Link href="/acerca/asesores">
                  <a title="Asesores Certificados" className="py-7 d-block text-raven">
                    Asesores Certificados
                  </a>
                </Link>
                <Link href="/acerca/testimoniales">
                  <a title="Testimoniales" className="py-7 d-block text-raven">
                    Testimoniales
                  </a>
                </Link>
              </Collapse>
            </div>
            <div className="py-10">
              <h6 className="mb-0 py-10 text-uppercase text-primary font-size-15 font-weight-bold">Contacto</h6>
              <Link href="/acerca/contacto">
                <a title="Contáctanos" className="py-7 d-block text-raven">
                  Contáctanos
                </a>
              </Link>
              <a
                title="Escríbenos a info@yucatanpremier.com"
                href="mailto:info@yucatanpremier.com"
                className="py-7 d-block text-raven"
              >
                Envíanos un email
              </a>
              <div className="py-10">
                <div className="py-10">
                  <Button
                    tag="a"
                    color="warning"
                    size="lg"
                    title="Llámanos al 999 944-1214"
                    href="/buscar/propiedad"
                    className="py-15 font-size-base"
                    block
                  >
                    Buscar una propiedad
                  </Button>
                </div>
                <div className="py-10">
                  <Button
                    tag="a"
                    color="primary"
                    size="lg"
                    title="Llámanos al 999 944-1214"
                    href="tel:9999441214"
                    className="py-15 font-size-base"
                    block
                  >
                    Llámanos al 999 944-1214
                  </Button>
                </div>
                <div className="py-10">
                  <Button
                    tag="a"
                    color="malachite"
                    size="lg"
                    title="Escríbenos por WhatsApp"
                    href="https://wa.me/5219993351899"
                    className="py-15 font-size-base"
                    target="_blank"
                    block
                  >
                    Escríbenos por WhatsApp
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
})
