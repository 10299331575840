import { BurguerMenu } from 'components/BurguerMenu'
import { FlyoutFavorites } from 'components/FlyoutFavorites'
import { FlyoutSearchForm } from 'components/FlyoutSearchForm'
import { HeaderBrand } from 'components/HeaderBrand'
import { RENT_ROLE, SALE_ROLE } from 'consts'
import { useStoreActions, useStoreState } from 'hooks/useStore'
import { ComponentType, Fragment } from 'react'
import { MdSearch } from 'react-icons/md'
import { RoleModel } from 'utils/role'

interface Props {
  role?: RoleModel
  category?: any
  zone?: any
  favorites?: any
}

export const SearchHeaderMobile: ComponentType<Props> = ({ role, category, zone, favorites }) => {
  const invader = useStoreState((state) => state.ui.invader)
  const setInvader = useStoreActions((actions) => actions.ui.setInvader)

  const nodes = [
    role && (
      <span className="font-size-15 font-weight-500 text-nowrap">
        {role.uuid === SALE_ROLE && 'Comprar'}
        {role.uuid === RENT_ROLE && 'Rentar'}
      </span>
    ),
    category && <span className="font-size-15 font-weight-500 text-nowrap">{category.pluralName}</span>,
    zone && <span className="font-size-15 font-weight-500 text-nowrap">{zone.name}</span>,
  ].filter(Boolean)

  return (
    <>
      <div className="h-70px d-flex flex-nowrap">
        <HeaderBrand className="w-60px flex-shrink-0" />
        <div
          className="d-flex align-self-center flex-grow-1 bg-athens-gray rounded-md cursor-pointer overflow-auto scroller"
          onClick={() => setInvader('search-form')}
        >
          <div className="py-7 px-20 mx-auto d-flex align-items-center">
            <div className="pr-10">
              <MdSearch size={20} className="text-primary" />
            </div>
            {nodes.map((node, index) => (
              <Fragment key={index}>
                {index !== 0 && <span className="px-10">·</span>}
                {node}
              </Fragment>
            ))}
          </div>
        </div>
        <BurguerMenu className="p-0 w-60px border-0 flex-shrink-0" favorites={favorites} showFavoritesIndicator />
      </div>
      {invader === 'favorites' && <FlyoutFavorites favorites={favorites} />}
      {invader === 'search-form' && <FlyoutSearchForm role={role} category={category} zone={zone} />}
    </>
  )
}
