import { QueryHookOptions, useQuery } from '@apollo/react-hooks'
import { BLOG_POSTS_QUERY } from 'apollo'
import clean from 'clean-deep'
import { merge } from 'merge-anything'

const DEFAULTS = {
  variables: {
    query: {
      key: process.env.NEXT_PUBLIC_CONTENT_API_KEY,
      include: 'authors,tags',
      filter: 'tag:-[testimonios,archivo]',
    },
  },
}

export const useBlogPostsQuery = (options: QueryHookOptions<any, any> = {}) =>
  useQuery(BLOG_POSTS_QUERY, merge(DEFAULTS, clean(options)))
