import gql from 'graphql-tag'

export const FAVORITE_DETAIL_FRAGMENT = gql`
  fragment FavoriteDetail on Favorite {
    uuid
    property
  }
`

export const FAVORITES_QUERY = gql`
  query Favorites {
    favorites @rest(type: "FavoritesPayload", path: "favorites") {
      total
      data @type(name: "Favorite") {
        ...FavoriteDetail
      }
    }
  }
  ${FAVORITE_DETAIL_FRAGMENT}
`

export const CREATE_FAVORITE_MUTATION = gql`
  mutation CreateFavorite($input: Object!) {
    createFavorite(input: $input) @rest(type: "CreateFavoritePayload", path: "favorites", method: "POST") {
      ...FavoriteDetail
    }
  }
  ${FAVORITE_DETAIL_FRAGMENT}
`

export const DESTROY_FAVORITE_MUTATION = gql`
  mutation DestroyFavorite($uuid: ID!, $input: Object!) {
    destroyFavorite(uuid: $uuid, input: $input)
      @rest(type: "DestroyFavoritePayload", path: "favorites/{args.uuid}/delete", method: "POST") {
      ...FavoriteDetail
    }
  }
  ${FAVORITE_DETAIL_FRAGMENT}
`
