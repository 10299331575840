import { AmpiBanner } from 'components/Banners'
import Link from 'next/link'
import { ComponentType } from 'react'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'
import { Button, Col, Container, Row } from 'reactstrap'

export const FooterMobile: ComponentType = () => (
  <div className="bg-porcelain">
    <Container>
      <Row>
        <Col className="pt-70 pb-55 text-center">
          <img alt="Yucatan Premier" src="/images/yp-brand.svg" width="177px" height="80px" />
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className="mb-15 text-uppercase text-blue-zodiac font-size-15 font-weight-bold">Servicios</h6>
          <Link href="/explorar/operacion/[roleSlug]" as="/explorar/operacion/en-venta">
            <a title="Comprar" className="text-raven font-size-15 line-height-lg">
              Comprar
            </a>
          </Link>
          <span className="px-10">·</span>
          <Link href="/explorar/operacion/[roleSlug]" as="/explorar/operacion/en-renta">
            <a title="Rentar" className="text-raven font-size-15 line-height-lg">
              Rentar
            </a>
          </Link>
          <span className="px-10">·</span>
          <a href="https://wa.me/5219993351899" title="Promover mi propiedad" className="text-malachite font-size-15 line-height-lg font-weight-bold">
            Promover mi propiedad
          </a>
        </Col>
      </Row>
      <Row className="pt-30">
        <Col>
          <h6 className="mb-15 text-uppercase text-blue-zodiac font-size-15 font-weight-bold">Compañía</h6>
          <Link href="/acerca">
            <a title="Sobre Nosotros" className="text-raven font-size-15 line-height-lg">
              Sobre Nosotros
            </a>
          </Link>
          <span className="px-10">·</span>
          <Link href="/acerca/asesores">
            <a title="Asesores Certificados" className="text-raven font-size-15 line-height-lg">
              Asesores Certificados
            </a>
          </Link>
          <span className="px-10">·</span>
          <Link href="/acerca/testimoniales">
            <a title="Testimoniales" className="text-raven font-size-15 line-height-lg">
              Testimoniales
            </a>
          </Link>
          <span className="px-10">·</span>
          <Link href="/acerca/politica-de-trabajo">
            <a title="Política de Trabajo" className="text-raven font-size-15 line-height-lg">
              Política de Trabajo
            </a>
          </Link>
        </Col>
      </Row>
      <Row className="pt-30">
        <Col>
          <h6 className="mb-15 text-uppercase text-blue-zodiac font-size-15 font-weight-bold">Contacto</h6>
          <Link href="/acerca/contacto">
            <a title="Contáctanos" className="text-raven font-size-15 line-height-lg">
              Contáctanos
            </a>
          </Link>
          <span className="px-10">·</span>
          <a
            title="Envíanos un email"
            href="mailto:info@yucatanpremier.com"
            className="text-raven font-size-15 line-height-lg"
          >
            Envíanos un email
          </a>
          <span className="px-10">·</span>
          <a
            title="Escríbenos por WhatsApp"
            href="https://wa.me/5219993351899"
            className="text-raven font-size-15 line-height-lg font-weight-bold"
            target="_blank"
          >
            Escríbenos por WhatsApp
          </a>
        </Col>
      </Row>
      <Row className="pt-30">
        <Col>
          <Button
            tag="a"
            color="primary"
            size="lg"
            title="Llámanos al 999 944-1214"
            href="tel:9999441214"
            className="py-15 font-size-base"
            block
          >
            Llámanos al 999 944-1214
          </Button>
        </Col>
      </Row>
      <Row className="py-50">
        <Col className="text-center">
          <a
            title="Facebook"
            href="https://www.facebook.com/yucatanpremier"
            target="_blank"
            className="text-raven px-24"
          >
            <FaFacebook size={24} />
          </a>
          <a title="Twitter" href="https://twitter.com/yucatanpremier" target="_blank" className="text-raven px-24">
            <FaTwitter size={24} />
          </a>
          <a
            title="Instagram"
            href="https://www.instagram.com/yucatanpremier"
            target="_blank"
            className="text-raven px-24"
          >
            <FaInstagram size={24} />
          </a>
        </Col>
      </Row>
      <Row>
        <Col>
          <AmpiBanner />
        </Col>
      </Row>
      <Row className="pt-55">
        <Col className="text-center">
          <Link href="/legal/politica-de-servicio">
            <a title="Política de Servicio" className="text-raven font-size-15 line-height-lg">
              Política de Servicio
            </a>
          </Link>
          <span className="px-10">·</span>
          <Link href="/legal/aviso-de-privacidad">
            <a title="Aviso de Privacidad" className="text-raven font-size-15 line-height-lg">
              Aviso de Privacidad
            </a>
          </Link>
          <span className="px-10">·</span>
          <a
            href="https://yucatanpremier.com.mx/sitemap.xml"
            title="Mapa del Sitio"
            className="text-raven font-size-15 line-height-lg "
            target="_blank"
          >
            Mapa del Sitio
          </a>
        </Col>
      </Row>
      <Row className="pt-24 pb-30">
        <Col className="text-center">
          <span className="text-raven font-size-15 line-height-lg">© {new Date().getFullYear()} Yucatán Premier</span>
        </Col>
      </Row>
    </Container>
  </div>
)
