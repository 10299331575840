import { useMutation } from '@apollo/react-hooks'
import { CREATE_FAVORITE_MUTATION, DESTROY_FAVORITE_MUTATION } from 'apollo'
import { Hearth } from 'components/Hearth'
import { ComponentType } from 'react'

interface Props {
  property: any
  onFavoriteCreate?: () => void
  onFavoriteDestroy?: () => void
}

export const PropertyHearth: ComponentType<Props> = ({ property, onFavoriteCreate, onFavoriteDestroy }) => {
  const [createFav, createFavResult] = useMutation(CREATE_FAVORITE_MUTATION, {
    refetchQueries: ['Favorites', 'Properties', 'Property'],
    onCompleted: () => onFavoriteCreate && onFavoriteCreate(),
    awaitRefetchQueries: true,
  })

  const [destroyFav, destroyFavResult] = useMutation(DESTROY_FAVORITE_MUTATION, {
    refetchQueries: ['Favorites', 'Properties', 'Property'],
    onCompleted: () => onFavoriteDestroy && onFavoriteDestroy(),
    awaitRefetchQueries: true,
  })

  const handleHearthClick = () => {
    if (property.favorite) {
      destroyFav({
        variables: { uuid: property.favorite.uuid, input: {} },
      })
    } else {
      createFav({ variables: { input: { property: property.uuid } } })
    }
  }

  return (
    <Hearth
      active={!!property.favorite}
      disabled={createFavResult.loading || destroyFavResult.loading}
      onClick={handleHearthClick}
    />
  )
}
