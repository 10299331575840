export const SALE_ROLE = 'sale' as const
export const SALE_SLUG = 'en-venta' as const
export const SALE_MODEL = { uuid: SALE_ROLE, slug: SALE_SLUG, query: `/${SALE_ROLE}/` } as const

export const RENT_ROLE = 'rent' as const
export const RENT_SLUG = 'en-renta' as const
export const RENT_MODEL = { uuid: RENT_ROLE, slug: RENT_SLUG, query: `/${RENT_ROLE}/` } as const

export const SALE_RENT_ROLE = 'sale-rent' as const
export const SALE_RENT_SLUG = 'en-venta-y-renta' as const
export const SALE_RENT_MODEL = { uuid: SALE_RENT_ROLE, slug: SALE_RENT_SLUG, query: undefined } as const

export const DEFAULT_ROLE_SLUG = SALE_RENT_SLUG
export const DEFAULT_CATEGORY_SLUG = 'propiedades' as const
export const DEFAULT_ZONE_SLUG = 'merida-y-yucatan' as const
