import gql from 'graphql-tag'

const BLOG_PAGINATION_DETAIL_FRAGMENT = gql`
  fragment BlogPaginationDetail on BlogPagination {
    page
    limit
    pages
    total
    next
    prev
  }
`

const BLOG_AUTHOR_DEFAILT_FRAGMENT = gql`
  fragment BlogAuthorDetail on BlogAuthor {
    id
    name
    slug
    profile_image
    cover_image
    bio
    website
    location
    facebook
    twitter
    meta_title
    meta_description
    url
  }
`

const BLOG_TAG_DEFAILT_FRAGMENT = gql`
  fragment BlogTagDetail on BlogTag {
    id
    name
    slug
    description
    feature_image
    visibility
    meta_title
    meta_description
    url
  }
`

const BLOG_POST_DETAIL_FRAGMENT = gql`
  fragment BlogPostDetail on BlogPost {
    id
    uuid
    title
    slug
    html
    comment_id
    feature_image
    featured
    created_at
    updated_at
    published_at
    custom_excerpt
    codeinjection_head
    codeinjection_foot
    custom_template
    canonical_url
    url
    page
    excerpt
    og_image
    og_title
    og_description
    twitter_image
    twitter_title
    twitter_description
    meta_title
    meta_description
    quoted_by
    quoted_at
    tags @type(name: "BlogTag") {
      ...BlogTagDetail
    }
    primary_tag @type(name: "BlogTag") {
      ...BlogTagDetail
    }
    authors @type(name: "BlogAuthor") {
      ...BlogAuthorDetail
    }
    primary_author @type(name: "BlogAuthor") {
      ...BlogAuthorDetail
    }
  }
  ${BLOG_TAG_DEFAILT_FRAGMENT}
  ${BLOG_AUTHOR_DEFAILT_FRAGMENT}
`

export const BLOG_POSTS_QUERY = gql`
  query BlogPosts($query: Object) {
    blogPosts(query: $query) @rest(type: "BlogPostsPayload", endpoint: "content", path: "posts?{args.query}") {
      posts @type(name: "BlogPost") {
        ...BlogPostDetail
      }
      meta @type(name: "BlogMeta") {
        pagination @type(name: "BlogPagination") {
          ...BlogPaginationDetail
        }
      }
    }
  }
  ${BLOG_POST_DETAIL_FRAGMENT}
  ${BLOG_PAGINATION_DETAIL_FRAGMENT}
`

export const BLOG_POSTS_BY_SLUG_QUERY = gql`
  query BlogPostsBySlug($slug: String!, $query: Object) {
    blogPostsBySlug(slug: $slug, query: $query)
      @rest(type: "BlogPostsBySlugPayload", endpoint: "content", path: "posts/slug/{args.slug}?{args.query}") {
      posts @type(name: "BlogPost") {
        ...BlogPostDetail
      }
    }
  }
  ${BLOG_POST_DETAIL_FRAGMENT}
`

export const BLOG_TAGS_QUERY = gql`
  query BlogTags($query: Object) {
    blogTags(query: $query) @rest(type: "BlogTagsPayload", endpoint: "content", path: "tags?{args.query}") {
      tags @type(name: "BlogTag") {
        ...BlogTagDetail
      }
      meta @type(name: "BlogMeta") {
        pagination @type(name: "BlogPagination") {
          ...BlogPaginationDetail
        }
      }
    }
  }
  ${BLOG_TAG_DEFAILT_FRAGMENT}
  ${BLOG_PAGINATION_DETAIL_FRAGMENT}
`

export const BLOG_TAGS_BY_SLUG_QUERY = gql`
  query BlogTagsBySlug($slug: String!, $query: Object) {
    blogTagsBySlug(slug: $slug, query: $query)
      @rest(type: "BlogTagsBySlugPayload", endpoint: "content", path: "tags/slug/{args.slug}?{args.query}") {
      tags @type(name: "BlogTag") {
        ...BlogTagDetail
      }
    }
  }
  ${BLOG_TAG_DEFAILT_FRAGMENT}
`
