import styled from 'styled-components'

interface WrapperProps {
  isElevated?: boolean
}

export const Card = styled.div<WrapperProps>`
  padding: 4px;
  border-radius: 7px;
  position: relative;
  transition: all 150ms ease-in-out;
  border: 1px solid transparent;
  overflow: hidden;

  background: ${({ isElevated }) => isElevated && 'white'};
  box-shadow: ${({ isElevated }) => isElevated && '0 1px 3px #c5ccd5'};

  :hover {
    border-color: #0078c9;
  }
`
