import { ComponentType, HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {
  url?: string
  position?: string
}

export const CoverBackground: ComponentType<Props> = ({ url, position, style, ...props }) => (
  <div
    style={{
      ...style,
      backgroundImage: url ? `url("${url}")` : undefined,
      backgroundPosition: position ?? 'center center',
      backgroundSize: 'cover',
    }}
    {...props}
  />
)
