import { BurguerMenu } from 'components/BurguerMenu'
import { FlyoutFavorites } from 'components/FlyoutFavorites'
import { FlyoutSearchForm } from 'components/FlyoutSearchForm'
import { HeaderBrand } from 'components/HeaderBrand'
import { useStoreState } from 'hooks/useStore'
import { ComponentType } from 'react'

interface Props {
  favorites?: any
}

export const HeaderMobile: ComponentType<Props> = ({ favorites }) => {
  const invader = useStoreState((state) => state.ui.invader)

  return (
    <>
      <div className="h-70px d-flex justify-content-between">
        <HeaderBrand className="w-60px" />
        <img alt="Yucatan Premier" src="/images/yp-logotype.svg" width="180" />
        <BurguerMenu className="p-0 w-60px border-0" favorites={favorites} showFavoritesIndicator />
      </div>
      {invader === 'favorites' && <FlyoutFavorites favorites={favorites} />}
      {invader === 'search-form' && <FlyoutSearchForm />}
    </>
  )
}
