import { ComponentType } from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 9px 24px 0 #e3e3e4;

  ${up('sm')} {
    flex-direction: row;
    border-radius: 5px;
  }
`

const Brand = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 25px;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  border-radius: 0 0 6px 6px;
  background-color: #00a25a;
  color: #ffffff;
  font-weight: bold;
  font-family: sans-serif;
  padding: 18px;

  ${up('sm')} {
    padding: 15px;
    border-radius: 0 5px 5px 0;
  }
`

export const AmpiBanner: ComponentType = () => (
  <Wrapper>
    <Brand>
      <img alt="A.M.P.I" src="/images/ampi-logo.png" height="80px" />
    </Brand>
    <Content>Socio A.M.P.I. MÉRIDA</Content>
  </Wrapper>
)
