import gql from 'graphql-tag'

export const AMENITY_DETAIL_FRAGMENT = gql`
  fragment AmenityDetail on Amenity {
    uuid
    name
  }
`

export const AMENITIES_QUERY = gql`
  query Amenities($query: Object) {
    amenities(query: $query) @rest(type: "AmenitiesPayload", path: "amenities?{args.query}") {
      total
      data @type(name: "Amenity") {
        ...AmenityDetail
      }
    }
  }
  ${AMENITY_DETAIL_FRAGMENT}
`
