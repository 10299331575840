import cx from 'classnames'
import Link from 'next/link'
import { ComponentType, HTMLAttributes } from 'react'

export const HeaderBrand: ComponentType<HTMLAttributes<HTMLAnchorElement>> = ({ className, ...props }) => (
  <Link href="/">
    <a title="Inicio" className={cx('d-flex align-items-center justify-content-center', className)} {...props}>
      <img alt="Yucatan Premier" src="/images/yp-brand-icon.svg" width="26px" height="30px" />
    </a>
  </Link>
)
