import gql from 'graphql-tag'

export const LEAD_DETAIL_FRAGMENT = gql`
  fragment LeadDetail on Lead {
    uuid
  }
`

export const CREATE_LEAD_MUTATION = gql`
  mutation CreateLead($input: Object!) {
    createLead(input: $input) @rest(type: "LeadsPayload", path: "leads", method: "POST") {
      ...LeadDetail
    }
  }
  ${LEAD_DETAIL_FRAGMENT}
`
