import gql from 'graphql-tag'

export const PROPERTY_MEDIA_DETAIL_FRAGMENT = gql`
  fragment PropertyMediaDetail on PropertyMedia {
    uuid
    type
    title
    position
    publicUrl
    secureUrl
    thumbnailUrl
  }
`
