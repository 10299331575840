import { Media } from 'media'
import { forwardRef } from 'react'
import { FlyoutMenuDesktop } from './FlyoutMenuDesktop'
import { FlyoutMenuMobile } from './FlyoutMenuMobile'

interface Props {
  favorites?: any
}

export const FlyoutMenu = forwardRef<HTMLDivElement, Props>(({ favorites }, ref) => (
  <>
    <Media at="xs">
      <FlyoutMenuMobile ref={ref} favorites={favorites} />
    </Media>
    <Media greaterThan="xs">
      <FlyoutMenuDesktop ref={ref} />
    </Media>
  </>
))
