import gql from 'graphql-tag'

export const CONVERSION_DETAIL_FRAGMENT = gql`
  fragment ConversionDetail on Conversion {
    uuid
    src
    property
  }
`

export const CREATE_RECORD = gql`
  mutation CreateRecord($u: String!, $c:String!, $s:String!) {
    createRecord(input: { u: $u, c: $c, s: $s  }) @rest(
      type: "Record", path: "properties/conversions", method: "POST"
    ) {
      ...ConversionDetail
    }
  }
  ${CONVERSION_DETAIL_FRAGMENT}
`
