import { QueryHookOptions, useQuery } from '@apollo/react-hooks'
import { ZONES_QUERY } from 'apollo'
import { merge } from 'merge-anything'

const DEFAULTS = {
  variables: {
    query: {
      sort: 'position',
    },
  },
}

export const useZonesQuery = (options: QueryHookOptions<any, any> = {}) =>
  useQuery(ZONES_QUERY, merge(DEFAULTS, options))
