import { PanModal } from 'components/PanModal'
import { DEFAULT_CATEGORY_SLUG, DEFAULT_ROLE_SLUG, DEFAULT_ZONE_SLUG } from 'consts'
import { Formik } from 'formik'
import { useCategoriesQuery } from 'hooks/useCategoriesQuery'
import { useStoreActions } from 'hooks/useStore'
import { useZonesQuery } from 'hooks/useZonesQuery'
import { useRouter } from 'next/router'
import { ComponentType } from 'react'
import usePortal from 'react-cool-portal'
import { Button, ButtonGroup, CustomInput, Form, FormGroup, Input } from 'reactstrap'
import { RoleModel } from 'utils/role'

interface Props {
  role?: RoleModel
  category?: any
  zone?: any
}

export const FlyoutSearchForm: ComponentType<Props> = ({ role, category, zone }) => {
  const setInvader = useStoreActions((actions) => actions.ui.setInvader)
  const router = useRouter()
  const categoriesResult = useCategoriesQuery()
  const zonesResult = useZonesQuery()

  const { Portal } = usePortal({
    clickOutsideToHide: false,
    escToHide: false,
    internalShowHide: false,
  })

  return (
    <Portal>
      <PanModal onClose={() => setInvader('')}>
        <Formik
          initialValues={{
            role: role?.slug ?? '',
            category: category?.slug ?? '',
            zone: zone?.slug ?? '',
          }}
          onSubmit={({ category, role, zone }) => {
            const categorySlug = category || DEFAULT_CATEGORY_SLUG
            const roleSlug = role || DEFAULT_ROLE_SLUG
            const zoneSlug = zone || DEFAULT_ZONE_SLUG

            router.push('/[categorySlug]/[roleSlug]/[zoneSlug]', `/${categorySlug}/${roleSlug}/${zoneSlug}`)
          }}
        >
          {({ values, handleChange, handleSubmit }) => {
            const valid = Object.values(values).filter(Boolean).length >= 2

            const selectedCategory = categoriesResult.data?.categories.data.find(
              ({ slug }: any) => slug === values.category,
            )

            return (
              <Form className="px-20 py-30" onSubmit={handleSubmit}>
                <FormGroup className="mb-20">
                  <ButtonGroup className="d-flex">
                    <Button
                      tag="label"
                      className="flex-fill mb-0"
                      color={values.role === 'en-venta' ? 'primary' : 'light'}
                    >
                      Comprar
                      <Input
                        type="radio"
                        name="role"
                        value="en-venta"
                        className="d-none"
                        checked={values.role === 'en-venta'}
                        onChange={handleChange}
                      />
                    </Button>
                    <Button
                      tag="label"
                      className="flex-fill mb-0"
                      color={values.role === 'en-renta' ? 'primary' : 'light'}
                    >
                      Rentar
                      <Input
                        type="radio"
                        name="role"
                        value="en-renta"
                        className="d-none"
                        checked={values.role === 'en-renta'}
                        onChange={handleChange}
                      />
                    </Button>
                  </ButtonGroup>
                </FormGroup>
                <FormGroup className="mb-20">
                  <CustomInput
                    type="select"
                    name="category"
                    id="category"
                    value={values.category}
                    onChange={handleChange}
                  >
                    <option value="">Elige una categoría</option>
                    {categoriesResult.data?.categories.data.map((category: any) => (
                      <option key={category.uuid} value={category.slug}>
                        {category.pluralName}
                      </option>
                    ))}
                  </CustomInput>
                </FormGroup>
                <FormGroup className="mb-20">
                  <CustomInput type="select" name="zone" id="zone" value={values.zone} onChange={handleChange}>
                    <option value="">Elige una zona</option>
                    {zonesResult.data?.zones.data.map((zone: any) => (
                      <option key={zone.uuid} value={zone.slug}>
                        {zone.name}
                      </option>
                    ))}
                  </CustomInput>
                </FormGroup>
                <Button color="primary" type="submit" className="px-20" disabled={!valid} block>
                  Buscar {selectedCategory?.pluralName}
                </Button>
                <a
                  title="Promover mi propiedad"
                  href="https://wa.me/5219993351899"
                  className="mt-20 button-promote button-promote_mobile_searchform font-size-14 font-weight-bold font-size-15"
                >
                  Promover mi propiedad
                </a>
              </Form>
            )
          }}
        </Formik>
      </PanModal>
    </Portal>
  )
}
