import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import { PropertyCard } from 'components/Property'
import { useStoreActions } from 'hooks/useStore'
import { ComponentType, RefObject, useEffect, useRef } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { GoX } from 'react-icons/go'
import { Col, Container, Row } from 'reactstrap'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  overflow: auto;
  z-index: 1030;
  background: #f8f9fa;

  ${up('sm')} {
    top: 70px;
  }
`

const CloseButton = styled.button`
  color: #a2aab4;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  z-index: 1030;
  border: 0;
  background: white;
  outline: none;

  ${up('sm')} {
    display: none;
  }
`

const Intro = styled.div`
  padding: 40px 0 20px 0;

  ${up('sm')} {
    padding: 56px 0 24px 0;
  }
`

export const FlyoutFavorites: ComponentType<{
  favorites?: any
  refsDeps?: RefObject<HTMLElement>[]
}> = ({ favorites, refsDeps = [] }) => {
  const setInvader = useStoreActions((actions) => actions.ui.setInvader)
  const container = useRef<HTMLDivElement>(null)

  useOnclickOutside([container, ...refsDeps], () => {
    setInvader('')
  })

  useEffect(() => {
    if (container.current) {
      disableBodyScroll(container.current)
    }

    return clearAllBodyScrollLocks
  }, [])

  return (
    <Wrapper ref={container}>
      <CloseButton onClick={() => setInvader('')}>
        <GoX size={20} />
      </CloseButton>
      <Container>
        <Row>
          <Col>
            <Intro>
              <h6 className="font-size-14 font-weight-bold text-uppercase text-carise-red mb-15">
                Propiedades Favoritas
              </h6>
              <h3 className="font-size-26 font-family-serif font-weight-bold mb-10">
                {favorites?.data.length} seleccionadas
              </h3>
              <p className="font-size-17 mb-0">
                Añade más propiedades o remueve aquellas que ya no sean de tu interés.
              </p>
            </Intro>
          </Col>
        </Row>
        <div className="five-row">
          {favorites?.data.map((favorite: any) => (
            <div key={favorite.uuid} className="five-col-sm-1 py-24">
              <PropertyCard property={favorite.property} className="h-100" />
            </div>
          ))}
        </div>
      </Container>
    </Wrapper>
  )
}
