import { QueryHookOptions, useQuery } from '@apollo/react-hooks'
import { CATEGORIES_QUERY } from 'apollo'
import { merge } from 'merge-anything'

const DEFAULTS = {
  variables: {
    query: { sort: 'position' },
  },
}

export const useCategoriesQuery = (options: QueryHookOptions<any, any> = {}) =>
  useQuery(CATEGORIES_QUERY, merge(DEFAULTS, options))
