import { BurguerMenu } from 'components/BurguerMenu'
import { FlyoutFavorites } from 'components/FlyoutFavorites'
import { useStoreState } from 'hooks/useStore'
import Router from 'next/router'
import { ComponentType } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { Button } from 'reactstrap'

interface Props {
  property?: any
  favorites?: any
}

export const PropertyHeaderMobile: ComponentType<Props> = ({ property, favorites }) => {
  const invader = useStoreState((state) => state.ui.invader)

  return (
    <>
      <div className="h-70px d-flex justify-content-between">
        <Button
          color="link"
          className="w-60px p-0 d-flex align-items-center justify-content-center border-0"
          onClick={Router.back}
        >
          <FiChevronLeft size={25} className="text-ghost" />
        </Button>
        {property && (
          <div className="d-flex align-items-center">
            <span className="px-5px font-weight-600">{property.category.name}</span>
            <span className="px-5px text-gray-chateau">{property.uuid}</span>
          </div>
        )}
        <BurguerMenu className="p-0 w-60px border-0" favorites={favorites} showFavoritesIndicator />
      </div>
      {invader === 'favorites' && <FlyoutFavorites favorites={favorites} />}
    </>
  )
}
