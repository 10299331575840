import { useBlogPostsQuery } from 'hooks/useBlogPostsQuery'
import Link from 'next/link'
import { forwardRef } from 'react'
import { Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  background: #f8f9fa;
  z-index: 1030;
  padding: 40px 0;
`

export const FlyoutMenuDesktop = forwardRef<HTMLDivElement>(({ }, ref) => {
  const { data } = useBlogPostsQuery({
    variables: { query: { limit: 3 } },
  })

  return (
    <Wrapper ref={ref}>
      <Container>
        <Row>
          <Col lg={2}>
            <h6 className="text-uppercase text-primary font-size-14 font-weight-bold mb-15">Servicios</h6>
            <ul className="list-unstyled">
              <li className="py-3px">
                <Link href="/explorar/operacion/[roleSlug]" as="/explorar/operacion/en-venta">
                  <a title="Comprar" className="text-raven font-size-14">
                    Comprar
                  </a>
                </Link>
              </li>
              <li className="py-3px">
                <Link href="/explorar/operacion/[roleSlug]" as="/explorar/operacion/en-renta">
                  <a title="Rentar" className="text-raven font-size-14">
                    Rentar
                  </a>
                </Link>
              </li>
              <li className="py-3px">
                <Link href="/acerca/promueve-tu-propiedad">
                  <a title="Promover mi propiedad" className="text-malachite font-size-14  font-weight-bold">
                    Promover mi propiedad
                  </a>
                </Link>
              </li>
              <li className="py-3px">
                <Link href="/buscar/propiedad">
                  <a title="buscar" className="text-raven font-size-14">
                    Buscar Propiedad
                  </a>
                </Link>
              </li>
            </ul>
          </Col>
          <Col lg={4}>
            <h6 className="text-uppercase text-primary font-size-14 font-weight-bold mb-15">Blog</h6>
            <ul className="list-unstyled">
              <li className="py-3px mb-10">
                <Link href="/blog">
                  <a title="Nuestro Blog" className="text-raven font-size-14">
                    Nuestro Blog
                  </a>
                </Link>
              </li>
              {data?.blogPosts.posts.map((post: any) => (
                <li key={post.id}>
                  <Link
                    href={{
                      pathname: '/blog/[postSlug]',
                      query: { postSlug: post.slug },
                    }}
                    as={`/blog/${post.slug}`}
                  >
                    <a title={post.title} className="text-raven font-size-14 truncate">
                      {post.title}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col lg={2}>
            <h6 className="text-uppercase text-primary font-size-14 font-weight-bold mb-15">Compañía</h6>
            <ul className="list-unstyled">
              <li className="py-3px">
                <Link href="/acerca">
                  <a title="Sobre Nosotros" className="text-raven font-size-14">
                    Sobre Nosotros
                  </a>
                </Link>
              </li>
              <li className="py-3px">
                <Link href="/acerca/asesores">
                  <a title="Asesores Certificados" className="text-raven font-size-14">
                    Asesores Certificados
                  </a>
                </Link>
              </li>
              <li className="py-3px">
                <Link href="/acerca/testimoniales">
                  <a title="Testimoniales" className="text-raven font-size-14">
                    Testimoniales
                  </a>
                </Link>
              </li>
            </ul>
          </Col>
          <Col lg={4}>
            <h6 className="text-uppercase text-primary font-size-14 font-weight-bold mb-15">Contacto</h6>
            <ul className="list-unstyled">
              <li className="py-3px">
                <Link href="/acerca/contacto">
                  <a title="Contáctanos" className="text-raven font-size-14">
                    Contáctanos
                  </a>
                </Link>
              </li>
              <li className="py-3px">
                <a title="Llámanos al 999 944-1214" href="tel:9999441214" className="text-raven font-size-14">
                  Llámanos al 999 944-1214
                </a>
              </li>
              <li className="py-3px">
                <a
                  title="Escríbenos por WhatsApp"
                  href="https://wa.me/5219993351899"
                  className="text-raven font-size-14 font-weight-bold"
                  target="_blank"
                >
                  Escríbenos por WhatsApp
                </a>
              </li>
              <li className="py-3px">
                <a
                  title="Escríbenos a info@yucatanpremier.com"
                  href="mailto:info@yucatanpremier.com"
                  className="text-raven font-size-14"
                >
                  Escríbenos a info@yucatanpremier.com
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
})
