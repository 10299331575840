export const pluralize = (size: number | null | undefined, plural: string, singular: string) => {
  if (size == null) {
    return plural
  }

  if (size === 1) {
    return `${size} ${singular}`
  }

  return `${size} ${plural}`
}
