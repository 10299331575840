import { Favorites } from 'components/Favorites'
import { HeaderBrand } from 'components/HeaderBrand'
import Link from 'next/link'
import { ComponentType } from 'react'
import { Col, Container, Row, Button } from 'reactstrap'

interface Props {
  favorites?: any
}

const doSomething = () => {
  console.log("Calling from doSomething >>>>>");
}


export const HeaderDesktop: ComponentType<Props> = ({ favorites }) => (
  <Container>
    <Row>
      <Col>
        <div className="d-flex h-70px">
          <HeaderBrand className="mr-15" />
          <div className="d-flex mr-auto align-items-center">
            <Link href="/acerca/promueve-tu-propiedad">
              <a title="Promover mi propiedad" className="nav-anchor button-promote button-promote_header">
                Promover mi propiedad
              </a>
            </Link>
            <Link href="/explorar/operacion/[roleSlug]" as="/explorar/operacion/en-venta">
              <a title="Comprar" className="nav-anchor">
                Comprar
              </a>
            </Link>
            <Link href="/explorar/operacion/[roleSlug]" as="/explorar/operacion/en-renta">
              <a title="Rentar" className="nav-anchor">
                Rentar
              </a>
            </Link>
            <Link href="/buscar/propiedad">
              <a title="buscar" className="nav-anchor">
                Buscar Propiedad
              </a>
            </Link>
          </div>
          <div className="d-flex">
            <Link href="/blog">
              <a title="Blog" className="nav-anchor">
                Blog
              </a>
            </Link>
            <Link href="/acerca">
              <a title="Sobre nosotros" className="nav-anchor">
                Sobre nosotros
              </a>
            </Link>
            <Link href="/acerca/contacto">
              <a title="Contacto" className="nav-anchor">
                Contacto
              </a>
            </Link>
            <Favorites favorites={favorites} />
          </div>
        </div>
      </Col>
    </Row>
  </Container>
)
