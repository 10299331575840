import cx from 'classnames'
import { FlyoutMenu } from 'components/FlyoutMenu'
import { useStoreActions, useStoreState } from 'hooks/useStore'
import { ButtonHTMLAttributes, ComponentType, useRef } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import usePortal from 'react-cool-portal'
import { GoThreeBars } from 'react-icons/go'
import { IoMdHeart } from 'react-icons/io'
import { Button } from 'reactstrap'

export const BurguerMenu: ComponentType<{
  favorites?: any
  showFavoritesIndicator?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>> = ({ favorites, showFavoritesIndicator, className, ...props }) => {
  const invader = useStoreState(state => state.ui.invader)
  const setInvader = useStoreActions(actions => actions.ui.setInvader)

  const button = useRef<HTMLButtonElement>(null)
  const container = useRef<HTMLDivElement>(null)

  const { Portal } = usePortal({
    clickOutsideToHide: false,
    escToHide: false,
    internalShowHide: false,
  })

  useOnclickOutside([button, container], () => setInvader(''), {
    disabled: invader !== 'burguer-menu',
  })

  return (
    <>
      <Button
        innerRef={button}
        color="link"
        size="lg"
        className={cx('d-flex align-items-center justify-content-center inversed-link-raven', className)}
        onClick={() => setInvader('burguer-menu')}
        {...props}
      >
        <GoThreeBars />
        {showFavoritesIndicator && favorites?.total > 0 && (
          <IoMdHeart size="12px" className="position-absolute text-carise-red" style={{ margin: '-5px 0 0 8px' }} />
        )}
      </Button>
      {invader === 'burguer-menu' && (
        <Portal>
          <FlyoutMenu ref={container} favorites={favorites} />
        </Portal>
      )}
    </>
  )
}
