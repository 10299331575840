import gql from 'graphql-tag'
import { PROPERTY_MEDIA_DETAIL_FRAGMENT } from './propertiesMedias'

export const ZONE_DETAIL_FRAGMENT = gql`
  fragment ZoneDetail on Zone {
    uuid
    name
    slug
    properties
    position
    cover @type(name: "PropertyMedia") {
      ...PropertyMediaDetail
    }
  }
  ${PROPERTY_MEDIA_DETAIL_FRAGMENT}
`

export const ZONES_QUERY = gql`
  query Zones($query: Object) {
    zones(query: $query) @rest(type: "Zones", path: "zones?{args.query}") {
      total
      data @type(name: "Zone") {
        ...ZoneDetail
      }
    }
  }
  ${ZONE_DETAIL_FRAGMENT}
`

export const ZONE_QUERY = gql`
  query Zone($query: String!) {
    zone(query: $query) @rest(type: "Zone", path: "zones/{args.query}") {
      ...ZoneDetail
    }
  }
  ${ZONE_DETAIL_FRAGMENT}
`
