import cx from 'classnames'
import { useFavoritesQuery } from 'hooks/useFavoritesQuery'
import { Media } from 'media'
import { ComponentType } from 'react'
import { RoleModel } from 'utils/role'
import { HeaderDesktop } from './HeaderDesktop'
import { HeaderMobile } from './HeaderMobile'
import { PropertyHeaderMobile } from './PropertyHeaderMobile'
import { SearchHeaderDesktop } from './SearchHeaderDesktop'
import { SearchHeaderMobile } from './SearchHeaderMobile'

export enum HeaderType {
  Default,
  Search,
  Property,
}

interface Props {
  role?: RoleModel
  category?: any
  zone?: any
  property?: any
  bordered?: boolean
  type?: HeaderType
}

export const Header: ComponentType<Props> = ({
  role,
  category,
  zone,
  property,
  bordered = true,
  type = HeaderType.Default,
}) => {
  const { data } = useFavoritesQuery()

  return (
    <div
      className={cx('print--header bg-white h-70px w-100 fixed-top', {
        'border-bottom': bordered,
      })}
    >
      <Media at="xs">
        {type === HeaderType.Default && <HeaderMobile favorites={data?.favorites} />}

        {type === HeaderType.Search && (
          <SearchHeaderMobile role={role} category={category} zone={zone} favorites={data?.favorites} />
        )}

        {type === HeaderType.Property && <PropertyHeaderMobile property={property} favorites={data?.favorites} />}
      </Media>
      <Media greaterThan="xs">
        {type === HeaderType.Default && <HeaderDesktop favorites={data?.favorites} />}
        {[HeaderType.Search, HeaderType.Property].includes(type) && (
          <SearchHeaderDesktop role={role} category={category} zone={zone} favorites={data?.favorites} />
        )}
      </Media>
    </div>
  )
}
