import gql from 'graphql-tag'
import { PROPERTY_MEDIA_DETAIL_FRAGMENT } from './propertiesMedias'

export const CATEGORY_DETAIL_FRAGMENT = gql`
  fragment CategoryDetail on Category {
    uuid
    name
    pluralName
    slug
    properties
    position
    cover @type(name: "PropertyMedia") {
      ...PropertyMediaDetail
    }
  }
  ${PROPERTY_MEDIA_DETAIL_FRAGMENT}
`

export const CATEGORIES_QUERY = gql`
  query Categories($query: Object) {
    categories(query: $query) @rest(type: "CategoriesList", path: "categories?{args.query}") {
      total
      data @type(name: "Category") {
        ...CategoryDetail
      }
    }
  }
  ${CATEGORY_DETAIL_FRAGMENT}
`

export const CATEGORY_QUERY = gql`
  query Category($query: String!) {
    category(query: $query) @rest(type: "Category", path: "categories/{args.query}") {
      ...CategoryDetail
    }
  }
  ${CATEGORY_DETAIL_FRAGMENT}
`
