import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import { ComponentType, useEffect, useRef } from 'react'
import { GoX } from 'react-icons/go'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.2);
`

const Filler = styled.div`
  flex: 1;
  position: relative;
`

const Close = styled.button`
  position: absolute;
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  box-shadow: 0 7px 18px 0 rgba(0, 5, 9, 0.33);
  border-radius: 40px;
  border: 0;
  bottom: 25px;
  left: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
`

const Content = styled.div`
  overflow: auto;
  background-color: #ffffff;
  box-shadow: 0 7px 18px 0 rgba(0, 5, 9, 0.33);
  max-height: 70vh;
`

export const PanModal: ComponentType<{ onClose: () => void }> = ({ onClose, children }) => {
  const content = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (content.current) {
      disableBodyScroll(content.current)
    }

    return clearAllBodyScrollLocks
  }, [])

  return (
    <Wrapper>
      <Filler>
        <Close type="button" onClick={onClose}>
          <GoX className="text-gray-chateau" />
        </Close>
      </Filler>
      <Content ref={content}>{children}</Content>
    </Wrapper>
  )
}
