import { FlyoutFavorites } from 'components/FlyoutFavorites'
import { useStoreActions, useStoreState } from 'hooks/useStore'
import { ComponentType, useEffect, useRef } from 'react'
import { GoHeart } from 'react-icons/go'
import { Button } from 'reactstrap'

export const Favorites: ComponentType<{ favorites?: any }> = ({ favorites }) => {
  const invader = useStoreState((state) => state.ui.invader)
  const setInvader = useStoreActions((actions) => actions.ui.setInvader)
  const button = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (favorites?.total === 0) {
      setInvader('')
    }
  }, [favorites])

  return (
    <>
      {favorites?.total > 0 && (
        <Button
          innerRef={button}
          color="carise-red"
          className="border-0 align-self-center text-nowrap"
          active={invader === 'favorites'}
          onClick={() => setInvader('favorites')}
          outline
        >
          Favoritos {favorites.total} <GoHeart />
        </Button>
      )}
      {invader === 'favorites' && <FlyoutFavorites favorites={favorites} refsDeps={[button]} />}
    </>
  )
}
