import gql from 'graphql-tag'
import { CATEGORY_DETAIL_FRAGMENT } from './categories'
import { FAVORITE_DETAIL_FRAGMENT } from './favorites'
import { PROPERTY_MEDIA_DETAIL_FRAGMENT } from './propertiesMedias'
import { USER_DETAIL_FRAGMENT } from './users'
import { ZONE_DETAIL_FRAGMENT } from './zones'

export const PROPERTY_DETAIL_FRAGMENT = gql`
  fragment PropertyDetail on Property {
    uuid
    name
    principalNotes
    description
    role
    seoTitle
    seoDescription
    seoTags
    currency
    promoted
    availability
    availableAt
    pin
    coverUrl
    comments
    favorite @type(name: "Favorite") {
      ...FavoriteDetail
    }
    owner @type(name: "User") {
      ...UserDetail
    }
    category @type(name: "Category") {
      ...CategoryDetail
    }
    zone @type(name: "Zone") {
      ...ZoneDetail
    }
    location @type(name: "Location") {
      type
      coordinates
    }
    rent @type(name: "Rent") {
      monthPrice
      agreementPrice
    }
    sale @type(name: "Sale") {
      price
      advancePayment
      advanceValidity
      downPayment
      balanceType
      other
      installments
      financingNotes
      financing @type(name: "Financing") {
        uuid
        name
      }
    }
    area @type(name: "Area") {
      total
      width
      depth
      built
      type
    }
    places @type(name: "Places") {
      floors
      rooms
      bathrooms
      halfBathrooms
      parkingSpacesCeiling
      parkingSpacesNoCeiling
    }
    measurements @type(name: "Measurements") {
      built
      improved
    }
    furnishings @type(name: "Furnishing") {
      uuid
      name
    }
    services @type(name: "Service") {
      uuid
      name
    }
    amenities @type(name: "Amenity") {
      uuid
      name
    }
    promotion @type(name: "Promotion") {
      uuid
      name
      color
    }
    gallery @type(name: "PropertyMedia") {
      ...PropertyMediaDetail
    }
    cover @type(name: "PropertyMedia") {
      ...PropertyMediaDetail
    }
  }
  ${FAVORITE_DETAIL_FRAGMENT}
  ${USER_DETAIL_FRAGMENT}
  ${CATEGORY_DETAIL_FRAGMENT}
  ${ZONE_DETAIL_FRAGMENT}
  ${PROPERTY_MEDIA_DETAIL_FRAGMENT}
`

export const PROPERTIES_QUERY = gql`
  query Properties($query: Object) {
    properties(query: $query) @rest(type: "PropertiesList", path: "properties?{args.query}") {
      total
      data @type(name: "Property") {
        ...PropertyDetail
      }
    }
  }
  ${PROPERTY_DETAIL_FRAGMENT}
`

export const PROPERTY_QUERY = gql`
  query Property($uuid: ID!) {
    property(uuid: $uuid) @rest(type: "Property", path: "properties/{args.uuid}/leaflet") {
      ...PropertyDetail
    }
  }
  ${PROPERTY_DETAIL_FRAGMENT}
`

export const PROPERTY_SEARCH_QUERY = gql`
  query SearchProperty($uuid: ID!) {
    property(uuid: $uuid) @rest(type: "Property", path: "properties/search?uuid={args.uuid}") {
      ...PropertyDetail
    }
  }
  ${PROPERTY_DETAIL_FRAGMENT}
`

