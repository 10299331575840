import gql from 'graphql-tag'

export const USER_DETAIL_FRAGMENT = gql`
  fragment UserDetail on User {
    uuid
    firstName
    lastName
    team
    email
    personalEmail
    phone1
    phone2
    phone3
    whatsapp
    isCertified
    avatar @type(name: "Avatar") {
      publicUrl
      secureUrl
      thumbnailUrl
    }
  }
`

export const AGENTS_QUERY = gql`
  query Agents($query: Object) {
    agents(query: $query) @rest(type: "AgentsPayload", path: "agents?{args.query}") {
      total
      data @type(name: "User") {
        ...UserDetail
      }
    }
  }
  ${USER_DETAIL_FRAGMENT}
`
