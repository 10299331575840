import gql from 'graphql-tag'

export const SERVICE_DETAIL_FRAGMENT = gql`
  fragment ServiceDetail on Service {
    uuid
    name
  }
`

export const SERVICES_QUERY = gql`
  query Services($query: Object) {
    services(query: $query) @rest(type: "ServicesPayload", path: "services?{args.query}") {
      total
      data @type(name: "Service") {
        ...ServiceDetail
      }
    }
  }
  ${SERVICE_DETAIL_FRAGMENT}
`
