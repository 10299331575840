import cx from 'classnames'
import { Card } from 'components/Card'
import { CoverBackground } from 'components/CoverBackground'
import { RENT_ROLE, SALE_ROLE } from 'consts'
import Link from 'next/link'
import numeral from 'numeral'
import { ComponentType, HTMLAttributes } from 'react'
import { GoStar } from 'react-icons/go'
import { FormattedMessage } from 'react-intl'
import { pluralize } from 'utils/plural'
import { findRole } from 'utils/role'
import { PropertyHearth } from './PropertyHearth'

interface Props extends HTMLAttributes<HTMLDivElement> {
  property: any
  horizontal?: boolean
  onFavoriteCreate?: () => void
  onFavoriteDestroy?: () => void
}

const createPropertyTitle = (property: any) => {
  const role = findRole(property.role)

  return <div className="mb-12 d-flex text-uppercase justify-content-between">
    <div
      className={cx('font-size-12 font-weight-bold property-name', {
        'text-primary': property.role.includes(SALE_ROLE),
        'text-neon-carrot': property.role === RENT_ROLE,
      })}
    >
      {property.name.slice(0, 90)}
    </div>
    <div className="d-flex pl-1 align-items-top">
      <span className="d-sm-none">
        <Link
          href="/[categorySlug]/[roleSlug]/[zoneSlug]/[propertyUuid]"
          as={`/${property.category.slug}/${role?.slug}/${property.zone.slug}/${property.uuid}`}
        >
          <a className="card-link stretched-link font-size-12 font-weight-bold text-trout">
            {property.uuid}
          </a>
        </Link>
      </span>
      <span className="d-sm-inline-block d-none">
        <Link
          href="/[categorySlug]/[roleSlug]/[zoneSlug]/[propertyUuid]"
          as={`/${property.category.slug}/${role?.slug}/${property.zone.slug}/${property.uuid}`}
        >
          <a className="card-link stretched-link font-size-12 font-weight-bold text-trout" target="_blank">
            {property.uuid}
          </a>
        </Link>
      </span>
    </div>
  </div>
}
const describeRooms = (places: any) => {
  return <h6 className="mb-12 font-weight-400 text-trout">
    {!!places.rooms && pluralize(places.rooms, 'recámaras', 'recámara')}
    {!!places.rooms && !!places.bathrooms && ', '}
    {!!places.bathrooms && pluralize(places.bathrooms, 'baños', 'baño')}
  </h6>
}

const formatPrice = (number: number) => {
  return <h6 className="font-size-15 font-weight-bold mb-0">
    {numeral(number).format('$0,0')}
  </h6>
}

const createCardContent = (property: any, className: string) => {
  return <div className={className}>
    {createPropertyTitle(property)}
    <h6 className="mb-5px">{property.zone.name}</h6>
    {(!!property.places.rooms || !!property.places.bathrooms) && describeRooms(property.places)}
    {!!property.sale?.price ? (
      <>
        {formatPrice(property.sale.price)} {property.currency}
      </>
    ) : !!property.rent?.monthPrice ? (
      <>
        {formatPrice(property.rent.monthPrice)} {property.currency}/mes
      </>
    ) : null}
  </div>
}

const createPromoLabel = (promo: any, color: string) => {
  return <span className="text-white text-center text-uppercase font-size-12 property-promotion" style={{ backgroundColor: color }}>
    {promo.name}
  </span >
}

export const PropertyCard: ComponentType<Props> = ({
  property,
  horizontal = false,
  onFavoriteCreate,
  onFavoriteDestroy,
  className,
  ...props
}) => {

  return (
    <Card
      isElevated={property.promoted}
      className={cx(className, {
        'd-flex align-items-center': horizontal,
      })}
      {...props}
    >
      <div
        className='position-relative bg-porcelain propertycard'>
        <div className="property-star">
          {property.promoted && <GoStar className="mr-4px" size="20" color="#FECE79" />}
        </div>
        {property.promotion && (createPromoLabel(property.promotion, property.promotion.color))}
        <PropertyHearth property={property} onFavoriteCreate={onFavoriteCreate} onFavoriteDestroy={onFavoriteDestroy} />
        {property.coverUrl && (
          <CoverBackground url={property.coverUrl} className='h-160px property-cover d-none d-sm-block rounded-md-top' />
        )}
        {property.coverUrl && (
          <CoverBackground url={property.coverUrl} className='h-330px property-cover d-block d-sm-none rounded-md-top' />
        )}
      </div>
      {createCardContent(property, 'p-10 d-block d-sm-none')}
      {createCardContent(property, 'p-10 d-none d-sm-block h-200px')}
    </Card >
  )
}
