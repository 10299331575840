import { ComponentType } from 'react'
import { IoMdHeart, IoMdHeartEmpty } from 'react-icons/io'
import styled from 'styled-components'

const Button = styled.button`
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  right: 0;
  border: 0;
  background: none;
  z-index: 5;
  outline: none;

  :disabled {
    opacity: 0.8;
  }
`

const Icon = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

interface Props {
  active: boolean
  disabled: boolean
  onClick: () => void
}

export const Hearth: ComponentType<Props> = ({ active, disabled, onClick }) => (
  <Button type="button" disabled={disabled} onClick={onClick}>
    {active && <Icon as={IoMdHeart} color="#DF226B" size={22} />}
    <Icon as={IoMdHeartEmpty} color="white" size={22} />
  </Button>
)
