import cx from 'classnames'
import { DEFAULT_CATEGORY_SLUG, DEFAULT_ROLE_SLUG, DEFAULT_ZONE_SLUG } from 'consts'
import { Formik } from 'formik'
import { useCategoriesQuery } from 'hooks/useCategoriesQuery'
import { useZonesQuery } from 'hooks/useZonesQuery'
import { useRouter } from 'next/router'
import { ComponentType } from 'react'
import { GoSearch } from 'react-icons/go'
import { Button, CustomInput, Form, InputGroupAddon, InputGroupText } from 'reactstrap'
import { RoleModel } from 'utils/role'

interface Props {
  role?: RoleModel
  category?: any
  zone?: any
}

export const SearchForm: ComponentType<Props> = ({ role, category, zone }) => {
  const router = useRouter()
  const categoriesResult = useCategoriesQuery()
  const zonesResult = useZonesQuery()

  return (
    <Formik
      initialValues={{
        role: role?.slug ?? '',
        category: category?.slug ?? '',
        zone: zone?.slug ?? '',
      }}
      onSubmit={({ category, role, zone }) => {
        const categorySlug = category || DEFAULT_CATEGORY_SLUG
        const roleSlug = role || DEFAULT_ROLE_SLUG
        const zoneSlug = zone || DEFAULT_ZONE_SLUG

        router.push('/[categorySlug]/[roleSlug]/[zoneSlug]', `/${categorySlug}/${roleSlug}/${zoneSlug}`)
      }}
      enableReinitialize
    >
      {({ values, handleChange, handleSubmit }) => {
        const valid = Object.values(values).filter(Boolean).length >= 2

        return (
          <Form onSubmit={handleSubmit}>
            <div className="d-flex">
              <CustomInput
                type="select"
                className={cx('border-0', 'font-weight-500', 'bg-athens-gray', 'no-outline', {
                  'text-black': !!values.role,
                })}
                name="role"
                id="role"
                style={{
                  width: 'max-content',
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                value={values.role}
                onChange={handleChange}
              >
                <option value="">Elige una acción</option>
                <option value="en-venta">Comprar</option>
                <option value="en-renta">Rentar</option>
              </CustomInput>
              <CustomInput
                type="select"
                className={cx('border-0', 'font-weight-500', 'bg-athens-gray', 'no-outline', {
                  'text-black': !!values.category,
                })}
                name="category"
                id="category"
                style={{
                  width: 'max-content',
                  borderRadius: 0,
                }}
                value={values.category}
                onChange={handleChange}
              >
                <option value="">Elige una categoría</option>
                {categoriesResult.data?.categories.data.map((category: any) => (
                  <option key={category.uuid} value={category.slug}>
                    {category.pluralName}
                  </option>
                ))}
              </CustomInput>
              {values.zone && (
                <InputGroupAddon addonType="append">
                  <InputGroupText
                    className="pr-0 border-0 bg-athens-gray text-raven font-weight-500"
                    style={{ borderRadius: 0 }}
                  >
                    en
                  </InputGroupText>
                </InputGroupAddon>
              )}
              <CustomInput
                type="select"
                className={cx('border-0', 'font-weight-500', 'bg-athens-gray', 'no-outline', {
                  'text-black': !!values.zone,
                })}
                name="zone"
                id="zone"
                style={{
                  width: 'max-content',
                  borderRadius: 0,
                }}
                value={values.zone}
                onChange={handleChange}
              >
                <option value="">Elige una zona</option>
                {zonesResult.data?.zones.data.map((zone: any) => (
                  <option key={zone.uuid} value={zone.slug}>
                    {zone.name}
                  </option>
                ))}
              </CustomInput>
              <InputGroupAddon addonType="append">
                <Button
                  color="primary"
                  type="submit"
                  className="px-20"
                  disabled={!valid}
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <GoSearch />
                </Button>
              </InputGroupAddon>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
